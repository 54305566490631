import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "notification-service"
    }}>{`Notification-service`}</h1>
    <p>{`The notification-service, GNAAL, is used for notifying customers of when important events occur that affect their reservations.
Currently only rebooking notifications are supported.
The notification-service API provides one endpoint to send a notification.
This endpoint will find the customer based on the orderId from the reservation, and proceed to send either an SMS or E-mail, if the customer has given consent.
If no consent is given, no message will be sent. The prioritized messaging service is SMS > E-mail.`}</p>
    <h2 {...{
      "id": "responses"
    }}>{`Responses`}</h2>
    <p>{`Responses
202 accepted
The input is accepted, and a notification will be sent to the affected customer.
400 bad request
There is something wrong with the request. For example, the request’s target user has not given consent.
404 not found
E-mail address or phone number not found.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      